@charset "UTF-8";
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.card {
  /* margin-bottom: 3rem; */
  border: 1rem solid var(--card-border-color);
}

.logo {
  font-size: 1rem !important;
  padding: 25px !important;
  /* font-family: 'Just Another Hand', cursive; */
  color: var(--a-visited-color) !important;
}

table {
  display: unset;
}

.logo a:hover {
  text-decoration: none;
  color: var(--card-border-color);
}

a:hover {
  text-decoration: none;
  color: var(--header-fore-color);
}

header {
  color: var(--card-border-color);
  background-color: var(--fore-color) !important;
  height: unset;
  overflow-x: hidden;
}

.drawer-toggle:before {
  color: var(--card-border-color);
  font-size: 80px;
  cursor: pointer;
  margin-right: -20px;
  padding: 20px;
}

@media screen and (max-width: 1000px) {
  .drawer-toggle:before {
    font-size: 3rem;
  }
}

p,
li,
ul,
th,
td,
tr,
em,
a,
b,
input,
label,
button {
  font-family: "Work Sans", sans-serif !important;
  color: #4c4c4c;
  font-size: 16px;
  line-height: 2.1rem;
}

@media screen and (min-width: 1000px) {
  p,
  li,
  ul,
  th,
  td,
  tr,
  em,
  a,
  b,
  input,
  label,
  button {
    font-size: 20px;
    line-height: 2.8rem;
  }
}

td {
  overflow-wrap: break-word !important;
}

h1 {
  color: var(--card-border-color);
  font-size: 40px !important;
  /* border-bottom: 1rem solid var(--card-border-color); */
  margin-top: 5rem;
}

@media screen and (min-width: 576px) {
  h1 {
    font-size: 70px !important;
  }
}

.subtitle {
  margin-top: -1.7rem;
  margin-left: 1rem;
  font-size: 25px;
}

@media screen and (max-width: 576px) {
  .subtitle {
    font-size: 35px;
  }
}

h2 {
  font-size: 38px;
  line-height: 4rem;
}

@media screen and (max-width: 1000px) {
  h2 {
    font-size: 28px;
    line-height: 3.5rem;
  }
}

h3 {
  font-size: 36px !important;
}

h2 > smaller {
  top: -1rem;
  font-size: 1.5rem !important;
}

h1,
h2,
h3,
.subtitle {
  font-family: "Martel", serif;
}

.container {
  position: relative;
  min-height: 100vh;
  padding: 0;
}
#content-wrap {
  padding-bottom: 4rem;
  margin-bottom: 70px;
}

#flexy {
  display: none;
}

@media screen and (min-width: 1279px) {
  #flexy {
    display: block;
  }
}

footer {
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 4rem;
}

footer > p,
footer b {
  font-size: 20px;
  line-height: 35px;
  color: var(--fore-color) !important;
}

.hr-like {
  border: none;
  border-top: 1px solid transparent;
  background-image: linear-gradient(
    to right,
    transparent,
    var(--border-color) 20%,
    var(--border-color) 80%,
    transparent
  );
  height: 1px;
  margin: 1em 0;
}

@media screen and (max-width: 1000px) {
  footer > p,
  footer b {
    font-size: small;
    line-height: 25px;
  }
}

#drawer {
  border: 1rem solid var(--card-border-color);
}

#drawer a {
  font-size: 20px !important;
}

nav {
  line-height: 0.2rem;
  border: unset;
  text-align: center;
  font-size: 1.4rem;
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.card.fluid {
  margin-bottom: 3rem;
}

nav a,
nav a:visited {
  color: unset;
}

a {
  color: var(--a-visited-color) !important;
}
